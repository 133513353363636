import * as React from "react"
import {Services2} from "../../components/services2";
import PageLayout from "../../components/pageLayout";
import Seo from "../../components/seo";

const BransjerPage = () => {
    return (
        <PageLayout>
            <Seo title={"Bransjer"}/>
            <Services2 />
        </PageLayout>
    )
}

export default BransjerPage
