import * as React from "react"
import Header3 from "./header3";

const PageNav = () => {
    return (
        <div className="bg-secondary">
            <div className="relative">
                <Header3 />
            </div>
        </div>
    )
}

export default PageNav
