import * as React from "react"
import PageNav from "./pageNav";
import {Footer} from "./footer";
import Layout from "./layout";

const PageLayout = ({children}) => {
    return (
        <Layout>
            <PageNav />
            {children}
            <Footer />
        </Layout>
    )
}

export default PageLayout
