import * as React from "react"
import {GatsbyImage} from "gatsby-plugin-image"
import {graphql, Link, useStaticQuery} from "gatsby";
import anleggIcon from "../images/icon-anlegg.svg";
import energiIcon from "../images/icon-energi.svg";
import landbrukIcon from "../images/icon-landbruk.svg";

export const Services2 = () => {
    const {
        anlegg,
        energi,
        landbruk,
        data
    } = useStaticQuery(graphql`
        {
            anlegg: file(relativePath: { eq: "anlegg.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        height: 530
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                }
            },
            energi: file(relativePath: { eq: "energi.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        height: 530
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                }
            },
            landbruk: file(relativePath: { eq: "landbruk.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        height: 530
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                }
            },
            data: markdownRemark(frontmatter: {slug: {eq: "bransjer"}}) {
                id
                frontmatter {
                    title
                    subTitle
                    anleggText
                    energiText
                    landbrukText
                }
                html
            },
        }
    `)

    return <div className="relative bg-secondary-light pt-16 pb-32 overflow-hidden">
        <div className="lg:text-center p-5">
            <h2 className="text-base text-primary font-semibold tracking-wide uppercase">{data.frontmatter.subTitle}</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-100 sm:text-4xl">
                {data.frontmatter.title}
            </p>
            <div className="prose mt-4 max-w-4xl text-xl text-gray-200 lg:mx-auto" dangerouslySetInnerHTML={{ __html: data.html }}></div>
        </div>
        <div className="mt-24 relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                    <div>
                        <div>
                            <span className="h-12 w-12 rounded-md flex items-center justify-center bg-primary">
                                <img src={anleggIcon} alt="Anlegg" />
                            </span>
                        </div>
                        <div className="mt-6">
                            <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">
                                Anlegg
                            </h2>
                            <p className="mt-4 text-lg text-gray-200">
                                {data.frontmatter.anleggText}
                            </p>
                            <div className="mt-6">
                                <Link to="/bransjer/anlegg"
                                   className="inline-flex px-4 py-2 border border-transparent text-secondary font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary-light">
                                    Les mer
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0">
                    <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                        <GatsbyImage
                            className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                            image={anlegg.childImageSharp.gatsbyImageData}
                            alt="Anlegg"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="mt-24">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                    <div>
                        <div>
                            <span className="h-12 w-12 rounded-md flex items-center justify-center bg-primary">
                                <img src={energiIcon} alt="Energi" />
                            </span>
                        </div>
                        <div className="mt-6">
                            <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">
                                Energi
                            </h2>
                            <p className="mt-4 text-lg text-gray-200">
                                {data.frontmatter.energiText}
                            </p>
                            <div className="mt-6">
                                <Link to="/bransjer/energi"
                                   className="inline-flex px-4 py-2 border border-transparent text-secondary font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary-light">
                                    Les mer
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                    <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                        <GatsbyImage
                            className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                            image={energi.childImageSharp.gatsbyImageData}
                            alt="Energi"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="mt-24 relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                    <div>
                        <div>
                            <span className="h-12 w-12 rounded-md flex items-center justify-center bg-primary">
                                <img src={landbrukIcon} alt="Landbruk" />
                            </span>
                        </div>
                        <div className="mt-6">
                            <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">
                                Landbruk
                            </h2>
                            <p className="mt-4 text-lg text-gray-200">
                                {data.frontmatter.landbrukText}
                            </p>
                            <div className="mt-6">
                                <Link to="/bransjer/landbruk"
                                   className="inline-flex px-4 py-2 border border-transparent text-secondary font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary-light">
                                    Les mer
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0">
                    <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                        <GatsbyImage
                            className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                            image={landbruk.childImageSharp.gatsbyImageData}
                            alt="Landbruk"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
}